.card_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    background-color: #D9D9D9;
    margin: 0;
    width: 200px;
    /* height: 400px; */
}

.content-text {
    margin: 10px 0;

}

.orderNum {
    margin: 10px 0 0 18px;
    font-weight: bold;
    font-size: 20px;

}

.orderName {
    margin-top: 5px;
    margin-left: 18px;
    font-size: 14px;
}

.productQ {
    margin-top: 5px;
    margin-left: 18px;
    font-size: 14px;

}

.divisor {
    text-align: right;
}

.next {
    background-color: #125C98;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    padding: 5px 7.5px;
    margin: 10px 0;
    border: 3px solid #000000;
    margin-left: 18px !important;
    width: 100px;
    height: 40px;
}

.next:hover {
    color: #000000;


}

.none {
    display: none;
}