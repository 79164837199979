.addNewEmplo {
    background-color: #D9D9D9;
    width: 220px;
    height: 60px;
    color: #125C98;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    border: 3px solid #000000;
    border-radius: 15px;
}