.containerEmail {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

.leftContainerEmail {
    width: 25%;
}

.rigthContainerEmail {
    width: 25%;
}

@media (min-width:800px) and (max-width:1700px) {

    .emailImg {
        max-width: 450px;
    }

    .centerContainerEmail {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 477px;
        height: 680px;
    }

    .topEmail {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .centerEmail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 477px;
        height: 420px;
    }

    .infoText {
        text-align: center;
        font-weight: bold;
        padding: 30px 30px;
    }

    .email {
        align-self: center;
        text-align: center;
        width: 350px;
        padding: 10px 20px;
        margin-top: 30px;
        border: 3px solid #555;
        border-radius: 10px;
    }

    input[type=text]:focus {
        border: 3px solid #555;
    }

    .sendEmail {
        justify-self: self-end;
        margin: 50px 0;
        padding: 13px 13px;
        font-family: 'Century Gothic';
        font-size: 30px;
        font-weight: bold;
        background-color: #FBC9FF;
        border: 2px solid #000000;
        cursor: pointer;
        border-radius: 20px;
    }

    .bottomEmail {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right: 2px;
        height: 120px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .returnBtnEmail {
        cursor: pointer;
        width: 33px;
        padding-right: 15px;
    }

    .totalEmail {
        font-size: 40px;
        margin-right: 15px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;


    }
}

@media (max-width:800px) {
   
    .emailImg {
        max-width: 350px;
    }

    .centerContainerEmail {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 394px;
        height: 650px;
    }

    .topEmail {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .centerEmail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 394px;
        height: 420px;
    }

    .infoText {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        padding: 30px 30px;
    }

    .email {
        align-self: center;
        text-align: center;
        width: 250px;
        padding: 10px 20px;
        margin-top: 20px;
    }

    input[type=text]:focus {
        border: 3px solid #555;
    }

    .sendEmail {
        justify-self: self-end;
        margin: 50px 0;
        padding: 13px 13px;
        font-family: 'Century Gothic';
        font-size: 20px;
        font-weight: bold;
        background-color: #FBC9FF;
        border: 2px solid #000000;
        cursor: pointer;
    }

    .bottomEmail {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .returnBtnEmail {
        cursor: pointer;
        width: 23px;
        padding-right: 15px;
    }

    .totalEmail {
        font-size: 30px;
        margin-right: 15px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;


    }
}

@media (min-width:200px) and (max-width:400px) {

   .centerContainerEmail {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 300px;
        height: 550px;
    }

    .topEmail {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 100px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }
    .emailImg {
        max-width: 250px;
    }

    .centerEmail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 310px;
    }

    .infoText {
        text-align: center;
        font-size: 12px;
        margin-top: 50px;
        font-weight: bold;
        padding: 20px 20px;
    }

    .email {
        align-self: center;
        text-align: center;
        font-size: 10px;
        width: 180px;
        padding: 5px 20px;
        margin-top: 30px;
       
    }

    input[type=text]:focus {
        border: 5px solid #555;
    }

    .sendEmail {
        justify-self: self-end;
        width: 110px;
        margin: 40px 0;
        padding: 7px 7px;
        font-family: 'Century Gothic';
        font-size: 20px;
        font-weight: bold;
        background-color: #FBC9FF;
        border: 2px solid #000000;
        cursor: pointer;
    }

    .bottomEmail {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right: 2px;
        height: 100px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .returnBtnEmail {
        cursor: pointer;
        width: 13px;
        padding-right: 7px;
    }

    .totalEmail {
        font-size: 20px;
        margin-right: 15px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;


    }
}


