.container-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    border: solid 4px #000000;
    margin: 20px 10px;
    height: 450px;

}

.title-order {
    display: flex;
    justify-content: center;
    align-items: center;

}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    font-weight: bold;
    color: #FFFFFF;

}
.statusDiv{
    display: flex;
    height: 85.4%;
    border-top: solid 4px #000000;
    width: 242px !important;
    flex-direction: column;
    width: fit-content;
    overflow-y: scroll;
    background-color: #D9D9D9;

    
}
