.container-employees {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: #316691;
}

.navEmpl {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
}

.btnAddNew {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}