.containerLog {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #316691;
}

.logITitle {
    width: 100%;
    text-align: center;
}

.logInText {
    background-color: #707A83;
    border: 4px solid #000000;
    font-family: 'Century Gothic';
    font-weight: bold;
    font-size: 80px;
    color: #316691;
    -webkit-text-stroke: 3px #B1BBC5;

}

.formDiv {
    display: flex;
    justify-content: center;
}

.form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 500px;
    border: 3px solid #000000;
    margin: 30px 0 40px 0;
    background-color: #D9D9D9;
}

.userImg {
    width: 120px;
    align-items: flex-start;
}

.user {
    background-color: #125C98;
    width: 120px;
    height: 20px;
    color: #FFFFFF;
    text-align: center;
    padding: 10px 15px;
    margin: 10px 0;
    border: 3px solid #000000;

}

.userInput {
    background-color: #FFFFFF;
    width: 250px;
    height: 20px;
    color: #125C98;
    text-align: center;
    padding: 10px 15px;
    margin: 10px 0;
    border: 3px solid #000000;

}

.password {
    background-color: #125C98;
    width: 120px;
    height: 20px;
    color: #FFFFFF;
    text-align: center;
    padding: 10px 15px;
    margin: 10px 0;
    border: 3px solid #000000;

}

.passwordInput {
    background-color: #FFFFFF;
    width: 250px;
    height: 20px;
    color: #125C98;
    text-align: center;
    padding: 10px 15px;
    margin: 10px 0;
    border: 3px solid #000000;

}

.logInBtn {
    background-color: #125C98;
    width: 100px;
    height: 50px;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 15px;
    margin: 5px 0;
    border: 3px solid #000000;

}

.logInBtn:hover {

    color: #000000;


}