.containerMenus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

.leftContainerMenu {
    width: 25%;
}

.rigthContainerMenu {
    width: 25%;
}

@media (min-width:800px) and (max-width:1700px) {

    .centerContainerMenu {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 477px;
        height: 680px;
    }

    .centerMenu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 477px;
        height: 415px;
    }


    .mcBtn {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        font-family: 'Century Gothic';
        font-weight: bold;
        width: 150px;
        margin-top: 10px;
        padding: 5px 10px 5px 10px;
        background-color: #FBC9FF;
        cursor: pointer;

    }

    .mcBig {
        width: 150px;
    }

    .menuContainer {
        margin: 0 20px;
    }

    .menuContainer:last-child img {
        margin-top: 20px;
        margin-left: 10px;
        width: 130px;
    }


    .topMenu {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .topMenu img {
        width: 270px;
        margin-top: 10px;
    }

    .mcTitleMenu {
        width: 350px !important;
    }

    .bottomMenu {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 2px;
        height: 120px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .bottomLeft,
    .bottomRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

    }

    .total {
        font-size: 30px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;

    }

    .vacio {
        text-align: center;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 17px;
        text-decoration: underline;
        margin-left: 25px;

    }


    .resumen {
        cursor: pointer;
        text-align: center;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 17px;
        text-decoration: underline;
        margin-left: 35px;


    }
}

@media (max-width:800px) {

    .centerContainerMenu {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 394px;
        height: 630px;
    }

    .centerMenu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 394px;
        height: 415px;
    }


    .mcBtn {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        font-family: 'Century Gothic';
        font-weight: bold;
        width: 150px;
        margin-top: 10px;
        padding: 5px 10px 5px 10px;
        background-color: #FBC9FF;
        cursor: pointer;

    }

    .mcBig {
        width: 130px;
    }

    .menuContainer {
        margin: 0 20px;
    }

    .menuContainer:last-child img {
        margin-top: 20px;
        margin-left: 10px;
        width: 110px;
    }



    .topMenu {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .topMenu img {
        width: 270px;
        margin-top: 10px;
    }

    .mcTitleMenu {
        width: 300px !important;
    }

    .bottomMenu {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 2px;
        height: 120px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .bottomLeft,
    .bottomRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

    }

    .total {
        font-size: 20px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;

    }

    .vacio {
        text-align: center;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 12px;
        text-decoration: underline;
        margin-left: 25px;

    }


    .resumen {
        cursor: pointer;
        text-align: center;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 12px;
        text-decoration: underline;
        margin-left: 35px;


    }
}

@media (min-width:200px) and (max-width:400px) {

    .centerContainerMenu {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 300px;
        height: 550px;
    }

    .centerMenu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 300px;
        height: 310px;
    }

    .mcBtn {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        font-family: 'Century Gothic';
        font-size: 10px;
        font-weight: bold;
        width: 100px;
        margin-top: 10px;
        padding: 5px 10px 5px 10px;
        background-color: #FBC9FF;
        cursor: pointer;

    }

    .mcBig {
        width: 80px;
        margin-left: 10px;
    }

    .menuContainer {
        margin: 0 20px;
    }

    .menuContainer:last-child img {
        margin-top: 20px;
        margin-left: 20px;
        width: 60px;
    }


    .topMenu {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 100px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .mcTitleMenu {
        width: 260px !important;
        margin-top: 5px;    }

    .bottomMenu {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 2px;
        height: 100px !important;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .bottomLeft,
    .bottomRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

    }

    .total {
        font-size: 16px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;

    }

    .vacio {
        text-align: center;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 12px;
        text-decoration: underline;
        margin-left: 25px;

    }


    .resumen {
        cursor: pointer;
        text-align: center;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 10px;
        text-decoration: underline;
        margin-left: 10px;


    }
}
