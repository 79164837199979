.containerDetails {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

.leftContainerDetails {
    width: 25%;
}

.rigthContainerDetails {
    width: 25%;
}

@media (min-width:800px) and (max-width:1700px) {

    .centerContainerDetails {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 477px;
        height: 680px;
    }

    .topDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .detailsTitle {
        width: 450px;
    }

    .menu {
        font-family: 'Century Gothic';
        font-weight: bold;
        font-size: 40px;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
        text-align: center;
        margin-top: 10px;
    }

    .menuPrice {
        font-family: 'Century Gothic';
        font-weight: bold;
        font-size: 30px;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
        text-align: center;
    }

    .centerDetails {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 477px;
        height: 250px;
    }

    .detailsMc {
        width: 190px;
        margin-left: 15px;

    }

    .pDetails {
        text-align: justify;
        margin: 0 10px 0 0px;
        padding: 10px 10px;
        font-family: 'Century Gothic';
        font-size: 15px;
        border: 3px dotted #B900A6;
    }

    .bottomMenu {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 2px;
        height: 120px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .bottomRightDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .bottomLeftDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }


    .add {
        text-align: center;
        cursor: pointer;
        margin-left: 40px;
        color: #5B00B7;
        font-family: 'Century Gothic';
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 16px;
        padding: 10px;
        background-color: #D7FFFA;
        border: 2px solid #FBC9FF;

    }

    .select {
        text-align: center;
        cursor: pointer;
        margin-right: 40px;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 16px;
        padding: 10px;
        background-color: #D7FFFA;
        border: 2px solid #FBC9FF;
    }
}

@media (max-width:800px) {

    .centerContainerDetails {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 394px;
        height: 630px;
    }

    .topDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .detailsTitle {
        width: 360px;
    }

    .menu {
        font-family: 'Century Gothic';
        font-weight: bold;
        font-size: 30px;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
        text-align: center;
        margin-top: 10px;
    }

    .menuPrice {
        font-family: 'Century Gothic';
        font-weight: bold;
        font-size: 20px;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
        text-align: center;
    }

    .centerDetails {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 390px;
        height: 250px;
    }

    .detailsMc {
        width: 145px;
        margin-left: 15px;

    }

    .pDetails {
        text-align: justify;
        margin: 0 10px 0 0px;
        padding: 10px 10px;
        font-family: 'Century Gothic';
        font-size: 12px;
        border: 3px dotted #B900A6;
    }

    .bottomMenu {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 2px;
        height: 120px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .bottomRightDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .bottomLeftDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }


    .add {
        text-align: center;
        cursor: pointer;
        margin-left: 40px;
        color: #5B00B7;
        font-family: 'Century Gothic';
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 12px;
        padding: 16px;
        background-color: #D7FFFA;
        border: 2px solid #FBC9FF;

    }

    .select {
        text-align: center;
        cursor: pointer;
        margin-right: 40px;
        font-size: 12px;
        font-family: 'Century Gothic';
        color: #5B00B7;
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        padding: 16px;
        background-color: #D7FFFA;
        border: 2px solid #FBC9FF;
    }
}

@media (min-width:200px) and (max-width:400px) {

    .centerContainerDetails {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 300px;
        height: 550px;
    }

    .topDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 100px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .detailsTitle {
        width: 270px;
    }

    .menu {
        font-family: 'Century Gothic';
        font-weight: bold;
        font-size: 20px;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
        text-align: center;
        margin-top: 30px;
    }

    .menuPrice {
        font-family: 'Century Gothic';
        font-weight: bold;
        font-size: 15px;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
        text-align: center;
    }

    .detailsContainer {
        width: 100%;
        height: 320px;
    }

    .centerDetails {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 200px;
    }

    .detailsMc {
        width: 100px;
        margin-left: 15px;

    }

    .pDetails {
        text-align: justify;
        margin: 0 10px 0 0px;
        padding: 5px 5px;
        font-family: 'Century Gothic';
        font-size: 10px;
        border: 3px dotted #B900A6;
    }

    .bottomMenu {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 2px;
        height: 120px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .bottomRightDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .bottomLeftDetails {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }


    .add,
    .select {
        text-align: center;
        cursor: pointer;
        margin-left: 30px;
        color: #5B00B7;
        font-family: 'Century Gothic';
        -webkit-text-stroke: 0.5px #000000;
        font-weight: bold;
        font-size: 10px;
        padding: 6px;
        background-color: #D7FFFA;
        border: 2px solid #FBC9FF;

    }

}