.containerCart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

.leftContainerCart {
    width: 25%;
}

.rigthContainerCart {
    width: 25%;
}

@media (min-width:800px) and (max-width:1700px) {

    .centerContainerCart {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 477px;
        height: 680px;
    }

    .topCart {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }


    .topCart img {
        width: 270px;
        margin-top: 10px;
    }

    .centerCart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .totalCart {
        margin-top: 15px;
        font-size: 40px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
    }

    .topCenterCartDiv {
        display: flex;
        flex-direction: row;
    }

    .bottomCenterCartDiv {
        display: flex;
        flex-direction: row;
    }

    .menuTitle {
        margin-top: 30px;
        font-size: 20px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
    }

    .mas {
        width: 30px;
        height: 30px;
        margin: 12% 2px 0 4px;
        cursor: pointer;
    }

    .menos {
        width: 30px;
        height: 30px;
        margin: 12% 4px 0 4px;
        cursor: pointer;
    }

    .cantidad {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        width: 30px;
        height: 30px;
        margin-top: 12%;
        background-color: #D9D9D9;
    }

    .precioUd {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        width: 90px;
        height: 30px;
        margin-top: 12%;
        margin-left: 15px;
        text-align: center;
        background-color: #D9D9D9;

    }

    .precioTotMen {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        width: 90px;
        height: 30px;
        margin-top: 12%;
        margin-left: 15px;
        background-color: #D9D9D9;
    }

    .mcJrCart {
        margin-top: 10px;
        width: 110px;
        height: 90px;
    }



    .bottomCart {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        position: absolute;
        align-self: flex-end;
        width: 100%;
        margin-right: 2px;
        height: 120px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .resumeCart {
        padding: 13px 13px;
        font-family: 'Century Gothic';
        font-weight: bold;
        background-color: #D7FFFA;
        border: 5px solid #FBC9FF;
        border-radius: 40px;
        cursor: pointer;
    }

    .finishCart {
        padding: 13px 13px;
        font-family: 'Century Gothic';
        font-weight: bold;
        background-color: #D7FFFA;
        border: 5px solid #FBC9FF;
        border-radius: 40px;
        cursor: pointer;

    }
}

@media (max-width:800px) {

    .centerContainerCart {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 394px;
        height: 630px;
    }

    .topCart {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }


    .topCart img {
        width: 270px;
        margin-top: 10px;
    }

    .centerCart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 394px;

    }

    .totalCart {
        margin-top: 15px;
        font-size: 30px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
    }

    .topCenterCartDiv {
        display: flex;
        flex-direction: row;
    }

    .bottomCenterCartDiv {
        display: flex;
        flex-direction: row;
    }

    .menuTitle {
        margin-top: 30px;
        font-size: 20px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
    }

    .mas {
        width: 30px;
        height: 30px;
        margin: 12% 2px 0 4px;
        cursor: pointer;
    }

    .menos {
        width: 30px;
        height: 30px;
        margin: 12% 4px 0 4px;
        cursor: pointer;
    }

    .cantidad {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        width: 30px;
        height: 30px;
        margin-top: 12%;
        background-color: #D9D9D9;
    }

    .precioUd {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        width: 60px;
        height: 30px;
        margin-top: 12%;
        margin-left: 15px;
        text-align: center;
        background-color: #D9D9D9;

    }

    .precioTotMen {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        width: 60px;
        height: 30px;
        margin-top: 12%;
        margin-left: 10px;
        margin-right: 25px;
        background-color: #D9D9D9;
    }

    .mcJrCart {
        margin-top: 10px;
        width: 80px;
        margin-left: 10px;

    }


    .bottomCart {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        position: absolute;
        align-self: flex-end;
        width: 100%;
        margin-right: 2px;
        height: 145px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .resumeCart {
        padding: 13px 13px;
        font-size: 12px;

        font-family: 'Century Gothic';
        font-weight: bold;
        background-color: #D7FFFA;
        border: 5px solid #FBC9FF;
        border-radius: 40px;
        cursor: pointer;
    }

    .finishCart {
        padding: 13px 13px;
        font-size: 12px;
        font-family: 'Century Gothic';
        font-weight: bold;
        background-color: #D7FFFA;
        border: 5px solid #FBC9FF;
        border-radius: 40px;
        cursor: pointer;

    }
}

@media (min-width:200px) and (max-width:400px) {

    .centerContainerCart {
        background-color: #BCEFFF;
        position: relative;
        border: 4px solid rgb(0, 0, 0);
        border-radius: 80px;
        width: 300px;
        height: 550px;
    }

    .topCart {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        margin-right: 2px;
        height: 100px;
        border-radius: 75px 75px 0 0;
        background-color: #D9D9D9;
    }

    .resumenimg {
        width: 150px !important;
        height: 40px;
        margin-top: 5px;

    }

    .pedidoimg {
        width: 160px !important;
        height: 40px;
        margin-top: 5px;
    }

    .centerCart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .totalCart {
        margin-top: 15px;
        text-align: center;
        font-size: 20px;
        margin-right: 90px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
    }

    .topCenterCartDiv {
        display: flex;
        flex-direction: row;
    }

    .bottomCenterCartDiv {
        display: flex;
        flex-direction: row;
    }

    .menuTitle {
        margin-top: 30px;
        font-size: 18px;
        margin-right: 90px;
        font-family: 'Century Gothic';
        font-weight: bold;
        color: #B900A6;
        -webkit-text-stroke: 1px #000000;
    }

    .mas {
        width: 20px;
        height: 20px;
        margin: 12% 4px 0 4px;
        cursor: pointer;
    }

    .menos {
        width: 20px;
        height: 20px;
        margin: 12% 4px 0 4px;
        cursor: pointer;
    }

    .cantidad {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-size: 14px;
        font-weight: bold;
        color: #B900A6;
        width: 20px;
        height: 20px;
        margin-top: 12%;
        background-color: #D9D9D9;
    }

    .precioUd {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-size: 14px;
        font-weight: bold;
        color: #B900A6;
        width: 60px;
        height: 20px;
        margin-top: 12%;
        margin-left: 15px;
        text-align: center;
        background-color: #D9D9D9;

    }

    .precioTotMen {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Century Gothic';
        font-size: 14px;
        font-weight: bold;
        color: #B900A6;
        width: 60px;
        height: 20px;
        margin-top: 12%;
        margin-left: 10px;
        margin-right: 25px;
        background-color: #D9D9D9;
    }

    .mcJrCart {
        margin-top: 10px;
        width: 50px;

    }


    .bottomCart {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        position: absolute;
        align-self: flex-end;
        width: 100%;
        margin-right: 2px;
        height: 100px;
        border-radius: 0 0 75px 75px;
        background-color: #D9D9D9;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }

    .resumeCart {
        padding: 13px 13px;
        font-size: 10px;
        font-family: 'Century Gothic';
        font-weight: bold;
        background-color: #D7FFFA;
        border: 5px solid #FBC9FF;
        border-radius: 40px;
        cursor: pointer;
    }

    .finishCart {
        padding: 13px 13px;
        font-size: 10px;
        font-family: 'Century Gothic';
        font-weight: bold;
        background-color: #D7FFFA;
        border: 5px solid #FBC9FF;
        border-radius: 40px;
        cursor: pointer;

    }
}